import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import Home from './Home';
import Dashboard from './Dashboard';
import Live from './Live';
import ResetPassword from './ResetPassword';
import NewPassword from './NewPassword';
import Login from './Login';
import NotFound from './NotFound';
import AuthenticatedRoute from './common/AuthenticatedRoute';

const Routing = () => {
  return (
    <BrowserRouter>
      <Switch>
        <AuthenticatedRoute path="/dashboard" component={Dashboard} />
        <Route path="/login" component={Login} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/new-password" component={NewPassword} />
        <Route path="/live/:organization_id" component={Live} />
        <Route path="/" component={Home} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routing; // To check if necessary
