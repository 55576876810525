import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

let openFn;

const defaultParams = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  autoHideDuration: 3000,
};

class Notifier extends React.Component {
  state = {
    open: false,
    params: null,
  };

  componentDidMount() {
    openFn = this.open;
  }

  open = params => {
    this.setState({
      open: true,
      params: { ...defaultParams, ...params },
    });
  };

  close = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { params } = this.state;

    if (!params) return null;

    const message = <span id="message-id">{params.message}</span>;
    return (
      <Snackbar
        {...params}
        message={message}
        onClose={this.close}
        open={this.state.open}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.close}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  }
}

export function openSnackbar(props) {
  openFn(props);
}

export default Notifier;
