import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CheckIcon from '@material-ui/icons/CheckCircle';
import CrossIcon from '@material-ui/icons/Close';
import lightGreen from '@material-ui/core/colors/lightGreen';
import red from '@material-ui/core/colors/red';

const styles = theme => ({
  checked: {
    color: lightGreen[500],
  },
  cross: {
    color: red[500],
  },
});

const StatusIcon = ({ checked, classes }) => {
  return checked ? (
    <CheckIcon className={classes.checked} />
  ) : checked === false ? (
    <CrossIcon className={classes.cross} />
  ) : null;
};

export default withStyles(styles)(StatusIcon);
