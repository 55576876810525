import { debounce } from 'lodash';

class Idle {
  timer = null;
  delay = null;
  onIdleFunc = null;

  constructor({ delay, onIdle }) {
    this.delay = delay;
    this.onIdleFunc = onIdle;
  }

  resetTimer = debounce(
    () => {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.onIdleFunc, this.delay);
    },
    200,
    { maxWait: 1000, leading: true },
  );

  start() {
    this.timer = setTimeout(this.onIdleFunc, this.delay);
    window.onload = this.resetTimer;
    window.onmousemove = this.resetTimer;
    window.onmousedown = this.resetTimer; // catches touchscreen presses as well
    window.ontouchstart = this.resetTimer; // catches touchscreen swipes as well
    window.onclick = this.resetTimer; // catches touchpad clicks as well
    window.onkeypress = this.resetTimer;
    window.onkeydown = this.resetTimer;
    window.addEventListener('scroll', this.resetTimer, true); // improved; see comments
  }
}

export default Idle;
