import React from 'react';
import { withFormik, Form } from 'formik';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import feathers from 'services/feathers';

import withStyles from '@material-ui/core/styles/withStyles';
import { Button, FormHelperText, TextField } from '@material-ui/core';

import { openSnackbar } from 'components/common/Notifier';

const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 300,
  },
  submit: {
    marginTop: 20,
    alignSelf: 'center',
  },
});

const keyToLabel = {
  email: 'email',
  firstname: 'Prénom',
  lastname: 'Nom',
};

const EditOrganization = ({
  classes,
  errors,
  isSubmitting,
  handleChange,
  values,
}) => {
  return (
    <Form className={classes.form}>
      {['name'].map(key => (
        <TextField
          key={key}
          id={key}
          label={keyToLabel[key]}
          required
          value={values[key]}
          onChange={handleChange}
          error={!!errors._form || !!errors[key]}
          helperText={errors[key] ? errors[key] : ''}
          margin="normal"
        />
      ))}
      {errors._form && <FormHelperText error>{errors._form}</FormHelperText>}
      <Button
        className={classes.submit}
        type="submit"
        variant="contained"
        color="primary"
        disabled={isSubmitting}
      >
        Valider
      </Button>
    </Form>
  );
};

export default compose(
  withRouter,
  inject('authenticationStore'),
  inject('modelStore'),
  withStyles(styles),
  withFormik({
    mapPropsToValues: ({ organization }) => ({
      name: organization.name,
    }),
    handleSubmit: async (
      values,
      {
        setSubmitting,
        setErrors,
        resetForm,
        props: { organization, onSubmitSuccess },
      },
    ) => {
      try {
        const data = await feathers
          .service('organizations')
          .patch(organization.id, values);
        openSnackbar({
          message: "L'organisation a été éditée",
          variant: 'success',
        });
        await onSubmitSuccess(data);
        resetForm();
      } catch (e) {
        if (e.errors && Object.keys(e.errors).length) {
          setErrors({ ...e.errors });
        } else {
          setErrors({ _form: e.message });
        }
      }
      setSubmitting(false);
    },

    displayName: 'EditOrganizationForm',
  }),
  observer,
)(EditOrganization);
