import React from 'react';
import { withStyles } from '@material-ui/core';
import Button from './Button';

const styles = theme => ({
  button: {
    marginTop: 30,
    fontSize: '1.8em',
    height: 100,
    width: 300,
    margin: 'auto',
  },
});

const Confirmation = ({ reset, classes }) => (
  <Button className={classes.button} action={reset}>
    OK
  </Button>
);

export default withStyles(styles)(Confirmation);
