import React from 'react';
import { observer, Provider as MobxProvider } from 'mobx-react';
import { authentication, model } from 'stores';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Notifier from './common/Notifier';
import Routing from './Routing';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#1965BF' },
    secondary: { main: '#eb2ecf' },
  },
  typography: {
    useNextVariants: true,
  },
});

function App() {
  return (
    <>
      <CssBaseline />
      <Notifier />
      <MobxProvider authenticationStore={authentication} modelStore={model}>
        <MuiThemeProvider theme={theme}>
          <Routing />
        </MuiThemeProvider>
      </MobxProvider>
    </>
  );
}

export default observer(App); // To check if necessary
