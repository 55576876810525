import { decorate, observable, action } from 'mobx';
import feathers from 'services/feathers';

class Model {
  user = null;
  organization = null;

  // Used by ADMIN
  advisors = null;

  hydrate = async () => {
    if (['ADMIN', 'SUPERVISOR'].includes(this.user.type)) {
      this.advisors = await feathers
        .service('users')
        .find({ query: { organization_id: this.user.organization_id } });
    }
    if (this.user.organization_id) {
      this.organization = await feathers
        .service('organizations')
        .get(this.user.organization_id);

      feathers.service('organizations').on('patched', props => {
        this.organization = props;
      });
    }
  };
}

decorate(Model, {
  user: observable,
  organization: observable,
  organizations: observable,
  advisors: observable,
  hydrate: action,
});

const model = new Model();

export default model;
