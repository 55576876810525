import React from 'react';
import { withFormik } from 'formik';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import feathers from 'services/feathers';
import {
  Button,
  withStyles,
  FormHelperText,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  colors,
} from '@material-ui/core';
import { Field } from 'formik';
import { SimpleFileUpload } from 'formik-material-ui';
import { openSnackbar } from 'components/common/Notifier';
import SectionTitle from 'components/common/SectionTitle';

const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  section: {
    marginTop: theme.spacing(3),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  longfield: {
    width: 400,
  },
  warning: {
    marginTop: theme.spacing(1),
    color: colors.orange[800],
  },
  preview: {
    maxHeight: 100,
  },
  block: {
    marginTop: 30,
    marginBottom: 30,
  },
  title: {
    display: 'flex',
    'align-items': 'stretch',
    'justify-content': 'space-between',
  },
});

const Form = ({
  modelStore: { organization },
  handleSubmit,
  classes,
  errors,
  isSubmitting,
  handleChange,
  values,
  dirty,
}) => {
  return (
    <>
      <SectionTitle>
        Paramètres
        <Button
          onClick={() =>
            window.open('https://www.xifab.com/aide-interface-tamtam', '_blank')
          }
        >
          Voir l'aide
        </Button>
      </SectionTitle>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Typography className={classes.section} variant="h6">
          Labels
        </Typography>
        <TextField
          id="welcome_title"
          label="Titre page d'accueil — Première ligne"
          margin="normal"
          placeholder="Bienvenue"
          className={classes.longfield}
          onChange={handleChange}
          error={!!errors._form || !!errors.welcome_title}
          value={values.welcome_title}
          helperText={errors.welcome_title}
        />
        <TextField
          id="welcome_title_2"
          label="Titre page d'accueil — Deuxième ligne"
          margin="normal"
          placeholder=""
          className={classes.longfield}
          onChange={handleChange}
          error={!!errors._form || !!errors.welcome_title_2}
          value={values.welcome_title_2}
          helperText={errors.welcome_title_2}
        />
        <TextField
          id="thank_you"
          label="Message de remerciement"
          margin="normal"
          placeholder="Merci !"
          className={classes.longfield}
          onChange={handleChange}
          error={!!errors._form || !!errors.thank_you}
          value={values.thank_you}
          helperText={errors.thank_you}
        />
        <TextField
          id="label_nom"
          label={'Libellé du champ "Indiquez votre nom"'}
          margin="normal"
          placeholder="Merci !"
          className={classes.longfield}
          onChange={handleChange}
          error={!!errors._form || !!errors.label_nom}
          value={values.label_nom}
          helperText={errors.label_nom}
        />

        <Typography className={classes.section} variant="h6">
          Deuxième champ de saisie visiteur (bouton de droite)
        </Typography>
        <FormControlLabel
          control={
            <Switch
              id="deuxieme_champs_activated"
              checked={values.deuxieme_champs_activated}
              onChange={handleChange}
            />
          }
          label={'Activer la page "deuxième champs de saisie visiteur"'}
        />
        {values.deuxieme_champs_activated && (
          <>
            <TextField
              id="deuxieme_champs_label"
              label={'Label du deuxième champs'}
              margin="normal"
              placeholder="Indiquez la raison de votre visite"
              className={classes.longfield}
              onChange={handleChange}
              error={!!errors._form || !!errors.deuxieme_champs_label}
              value={values.deuxieme_champs_label}
              helperText={errors.deuxieme_champs_label}
            />
          </>
        )}
        {values.appointment.activated && (
          <>
            <Typography className={classes.section} variant="h6">
              Deuxième champ de saisie visiteur pour les rendez-vous
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  id="deuxieme_champs_appointment_activated"
                  checked={values.deuxieme_champs_appointment_activated}
                  onChange={handleChange}
                />
              }
              label={
                'Activer la page "deuxième champs de saisie visiteur" pour les rendez-vous'
              }
            />
            {values.deuxieme_champs_appointment_activated && (
              <>
                <TextField
                  id="deuxieme_champs_appointment_label"
                  label={'Label du deuxième champs'}
                  margin="normal"
                  placeholder="Indiquez la raison de votre visite"
                  className={classes.longfield}
                  onChange={handleChange}
                  error={
                    !!errors._form || !!errors.deuxieme_champs_appointment_label
                  }
                  value={values.deuxieme_champs_appointment_label}
                  helperText={errors.deuxieme_champs_appointment_label}
                />
              </>
            )}
          </>
        )}

        <Typography className={classes.section} variant="h6">
          J'ai un rendez-vous
        </Typography>
        <FormControlLabel
          control={
            <Switch
              id="appointment.activated"
              checked={values.appointment.activated}
              onChange={handleChange}
            />
          }
          label={'Activer la page "J\'ai un rendez-vous"'}
        />
        {values.appointment.activated && (
          <>
            <TextField
              id="appointment.choose_advisor"
              label={'Message pour le choix du conseiller'}
              margin="normal"
              placeholder="Veuillez choisir votre conseiller"
              className={classes.longfield}
              onChange={handleChange}
              error={!!errors._form || !!errors.appointment}
              value={values.appointment.choose_advisor}
              helperText={
                errors.appointment && errors.appointment.choose_advisor
              }
            />
            <TextField
              id="appointment.label_have"
              label={'Libellé du champ "J\'ai un rendez-vous"'}
              margin="normal"
              placeholder="J'ai un rendez-vous"
              className={classes.longfield}
              onChange={handleChange}
              error={!!errors._form || !!errors.appointment}
              value={values.appointment.label_have}
              helperText={errors.appointment && errors.appointment.label_have}
            />
            <TextField
              id="appointment.label_not_have"
              label={
                'Libellé du champ "Je n\'ai pas de rendez-vous" (bouton de droite)'
              }
              margin="normal"
              placeholder="Je n'ai pas de rendez-vous"
              className={classes.longfield}
              onChange={handleChange}
              error={!!errors._form || !!errors.appointment}
              value={values.appointment.label_not_have}
              helperText={
                errors.appointment && errors.appointment.label_not_have
              }
            />
            <TextField
              id="appointment.thank_you"
              label={"Message de remerciement dans le cas d'un RDV"}
              margin="normal"
              placeholder=""
              className={classes.longfield}
              onChange={handleChange}
              error={!!errors._form || !!errors.appointment}
              value={values.appointment.thank_you}
              helperText={errors.appointment && errors.appointment.thank_you}
            />
          </>
        )}

        <Typography className={classes.section} variant="h6">
          Images
        </Typography>

        <div className={classes.block}>
          {organization.config.assets.background && (
            <img
              className={classes.preview}
              alt=""
              src={organization.config.assets.background}
            />
          )}
          <Field
            name="assets.background"
            label="Arrière plan"
            helperText={errors.assets && errors.assets.background}
            component={SimpleFileUpload}
          />
          {errors['assets.background'] && (
            <FormHelperText id="organization-create-error" error>
              {errors['assets.background']}
            </FormHelperText>
          )}
        </div>

        {errors._form && (
          <FormHelperText id="organization-create-error" error>
            {errors._form}
          </FormHelperText>
        )}
        {dirty && (
          <span className={classes.warning}>
            Le formulaire contient des changements non sauvegardés
          </span>
        )}
        <Button
          className={classes.submit}
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting || !dirty}
        >
          Mettre à jour
        </Button>
      </form>
    </>
  );
};

const reader = file => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });
};

export default compose(
  inject('modelStore'),
  withStyles(styles),
  withFormik({
    mapPropsToValues: ({ modelStore: { organization } }) => organization.config,
    enableReinitialize: true,
    handleSubmit: async (
      values,
      { setSubmitting, setErrors, resetForm, props: { modelStore } },
    ) => {
      try {
        await Promise.all(
          ['background'].map(async key => {
            if (values.assets[key] && typeof values.assets[key] === 'object') {
              try {
                const dataURL = await reader(values.assets[key]);
                const { location } = await feathers
                  .service('files')
                  .create({ dataURL });
                values.assets[key] = location;
              } catch (e) {
                setErrors({ [`assets.${key}`]: e.message });
                throw new Error();
              }
            }
          }),
        );
      } catch (e) {
        setSubmitting(false);
        return;
      }
      try {
        modelStore.organization = await feathers
          .service('organizations')
          .patch(modelStore.user.organization_id, { config: values });
        openSnackbar({
          message: 'Les paramètres de la borne ont bien été mis à jour',
          variant: 'success',
        });
        resetForm();
      } catch (e) {
        if (e.errors && Object.keys(e.errors).length) {
          setErrors({ ...e.errors });
        } else {
          setErrors({ _form: e.message });
        }
      }
      setSubmitting(false);
    },

    displayName: 'SettingsForm',
  }),
  observer,
)(Form);
