import React from 'react';
import { withStyles, Typography, colors } from '@material-ui/core';

const styles = theme => ({
  title: {
    margin: '40px 0',
    textAlign: 'center',
    color: colors.grey[400],
  },
});

const NoDataScreen = ({ children, classes }) => {
  return (
    <Typography variant="h5" className={classes.title}>
      {children}
    </Typography>
  );
};

export default withStyles(styles)(NoDataScreen);
