import React from 'react';
import { inject } from 'mobx-react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import feathers from 'services/feathers';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FormDialog from 'components/common/FormDialog';
import StatusIcon from 'components/common/StatusIcon';
import SectionTitle from 'components/common/SectionTitle';
import { openSnackbar } from 'components/common/Notifier';
import AddUser from '../../common/AddUser';
import EditOrganization from '../../common/EditOrganization';

const styles = theme => ({
  addButton: {
    margin: '30px 0',
  },
  table: {
    minWidth: 1000,
  },
  title: {
    margin: '30px auto 20px auto',
  },
});

const typeToLabel = {
  ADMIN: 'Admin',
  SUPERVISOR: 'Superviseur',
  ADVISOR: 'Conseiller',
};
class Get extends React.Component {
  state = {
    users: null,
    organization: null,
  };

  componentDidMount = () => {
    this.fetchOrganization();
    this.fetchUsers();
  };

  fetchUsers = async () => {
    const {
      match: {
        params: { organization_id },
      },
    } = this.props;
    const users = await feathers
      .service('users')
      .find({ query: { organization_id } });
    this.setState({ users });
  };

  fetchOrganization = async () => {
    const {
      match: {
        params: { organization_id },
      },
    } = this.props;

    const organization = await feathers
      .service('organizations')
      .get(organization_id);

    this.setState({ organization });
  };

  onDelete = id => async () => {
    if (
      !window.confirm(
        'Cet utilisateur sera supprimé de manière définitive\n\nÊtes-vous sûr?',
      )
    ) {
      return;
    }
    await feathers.service('users').remove(id);
    await this.fetchUsers();
    openSnackbar({
      message: "L'utilisateur a été supprimé",
      variant: 'success',
    });
  };

  render() {
    const { classes } = this.props;
    const { organization, users } = this.state;

    if (!organization || !users) return null;

    return (
      <>
        <SectionTitle>
          {organization.name}
          <FormDialog
            label="Editer"
            title="Editer l'organisation"
            render={close => (
              <EditOrganization
                organization={this.state.organization}
                onSubmitSuccess={async () => {
                  await this.fetchOrganization();
                  close();
                }}
              />
            )}
          />
        </SectionTitle>
        <Typography className={classes.title} variant="h5">
          Détails
        </Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>{organization.id}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Typography className={classes.title} variant="h5">
          Ajouter un utilisateur
        </Typography>
        <FormDialog
          label="Ajouter"
          title="Ajouter un utilisateur"
          render={close => (
            <AddUser
              organizationId={organization.id}
              onSubmitSuccess={() => {
                close();
                this.fetchUsers();
              }}
            />
          )}
        />
        {users && !!users.length && (
          <>
            <Typography className={classes.title} variant="h5">
              Utilisateurs
            </Typography>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Prénom</TableCell>
                  <TableCell>Nom</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Activé</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map(
                  ({ id, firstname, lastname, email, type, activated }) => (
                    <TableRow key={id}>
                      <TableCell>{id}</TableCell>
                      <TableCell>{firstname}</TableCell>
                      <TableCell>{lastname}</TableCell>
                      <TableCell>{email}</TableCell>
                      <TableCell>
                        <StatusIcon checked={activated} />
                      </TableCell>
                      <TableCell>{typeToLabel[type]}</TableCell>
                      <TableCell>
                        <IconButton
                          color="secondary"
                          onClick={this.onDelete(id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </>
        )}
      </>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles),
  inject('modelStore'),
)(Get);
