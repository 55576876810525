import React from 'react';
import background from 'resources/background.jpg';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  background: {
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    background: "url('" + background + "') no-repeat center center fixed",
    backgroundSize: 'cover',
    top: 0,
    zIndex: -1,
  },
});

const Background = ({ classes }) => {
  return <div className={classes.background} />;
};

export default withStyles(styles)(Background);
