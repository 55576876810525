import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import feathers from 'services/feathers';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PublishIcon from '@material-ui/icons/Publish';
import StatusIcon from 'components/common/StatusIcon';
import AddUser from 'components/Dashboard/pages/common/AddUser';
import FormDialog from 'components/common/FormDialog';
import SectionTitle from 'components/common/SectionTitle';
import { openSnackbar } from 'components/common/Notifier';
import EnableAdvisorSwitch from './common/EnableAdvisorSwitch';
import EditUser from './common/EditUser';
import { orderBy } from 'lodash';

const styles = theme => ({
  addButton: {
    margin: '30px 0',
  },
  title: {
    margin: '30px auto 20px auto',
  },
  table: {
    minWidth: 1400,
  },
});

const typeToLabel = {
  ADMIN: 'Admin',
  SUPERVISOR: 'Superviseur',
  ADVISOR: 'Conseiller',
};

class List extends Component {
  componentDidMount() {
    feathers.service('organizations').on('patched', this.onPatched);
  }
  componentWillUnmount() {
    feathers.service('organizations').removeListener('patched', this.onPatched);
  }
  onPatched = async props => {
    this.props.modelStore.organization = props;
  };
  onDelete = id => async () => {
    const { modelStore } = this.props;
    if (
      !window.confirm(
        'Cet utilisateur sera supprimé de manière définitive\n\nÊtes-vous sûr?',
      )
    ) {
      return;
    }
    await feathers.service('users').remove(id);
    modelStore.advisors = await feathers
      .service('users')
      .find({ query: { organization_id: modelStore.user.organization_id } });

    const enabledAdvisors = modelStore.organization.config.enabled_advisors;

    if (enabledAdvisors) {
      const nextEnabledAdvisors = modelStore.organization.config.enabled_advisors.slice();
      nextEnabledAdvisors.splice(nextEnabledAdvisors.indexOf(id), 1);
      modelStore.organization = await feathers
        .service('organizations')
        .patch(modelStore.organization.id, {
          config: {
            ...modelStore.organization.config,
            enabled_advisors: nextEnabledAdvisors,
          },
        });
    }

    openSnackbar({
      message: "L'utilisateur a été supprimé",
      variant: 'success',
    });
  };

  onPushToTop = id => async () => {
    const { modelStore } = this.props;
    if (
      !window.confirm(
        "Confirmez vous l'envoi de cet utilisateur en haut de la liste ?",
      )
    ) {
      return;
    }
    await feathers.service('users').patch(id, {
      action: 'pushed_at',
    });
    modelStore.advisors = await feathers
      .service('users')
      .find({ query: { organization_id: modelStore.user.organization_id } });
  };

  render() {
    const { classes, modelStore } = this.props;
    return (
      <div>
        <SectionTitle>Utilisateurs</SectionTitle>
        <Typography className={classes.title} variant="h5">
          Ajouter un utilisateur
        </Typography>
        <FormDialog
          label="Ajouter"
          title="Ajouter un conseiller"
          render={close => (
            <AddUser
              onSubmitSuccess={async userData => {
                close();
                modelStore.advisors = await feathers.service('users').find({
                  query: { organization_id: modelStore.user.organization_id },
                });
                modelStore.organization = await feathers
                  .service('organizations')
                  .patch(modelStore.organization.id, {
                    config: {
                      ...modelStore.organization.config,
                      enabled_advisors: [
                        ...modelStore.organization.config.enabled_advisors,
                        userData.id,
                      ],
                    },
                  });
              }}
            />
          )}
        />
        <Typography className={classes.title} variant="h6">
          Liste des utilisateurs
        </Typography>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Disponible</TableCell>
              <TableCell>Id</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Activé</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderBy(
              modelStore.advisors,
              ['pushed_at', 'id'],
              ['desc', 'asc'],
            ).map(({ id, firstname, lastname, email, type, activated }) => (
              <TableRow key={id}>
                <TableCell>
                  <IconButton color="primary" onClick={this.onPushToTop(id)}>
                    <PublishIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <EnableAdvisorSwitch id={id} />
                </TableCell>
                <TableCell>{id}</TableCell>
                <TableCell>
                  {firstname} {lastname}
                </TableCell>
                <TableCell>{typeToLabel[type]}</TableCell>
                <TableCell>{email}</TableCell>
                <TableCell>
                  <StatusIcon checked={activated} />
                </TableCell>
                <TableCell>
                  {(modelStore.user.type !== 'SUPERVISOR' ||
                    type !== 'ADMIN') && (
                    <FormDialog
                      title="Editer l'utilisateur"
                      renderButton={({ open }) => (
                        <IconButton color="primary" onClick={open}>
                          <EditIcon />
                        </IconButton>
                      )}
                      render={close => (
                        <EditUser
                          user_id={id}
                          onSubmitSuccess={async () => {
                            modelStore.advisors = await feathers
                              .service('users')
                              .find({
                                query: {
                                  organization_id:
                                    modelStore.user.organization_id,
                                },
                              });
                            close();
                          }}
                        />
                      )}
                    />
                  )}
                  {modelStore.user.id !== id &&
                    (modelStore.user.type !== 'SUPERVISOR' ||
                      type !== 'ADMIN') && (
                      <IconButton color="secondary" onClick={this.onDelete(id)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  inject('modelStore'),
  observer,
)(List);
