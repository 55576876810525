import React from 'react';
// import withStyles from '@material-ui/core/styles/withStyles';
import { inject, observer } from 'mobx-react';
import { Switch } from '@material-ui/core';
import { compose } from 'recompose';
import feathers from 'services/feathers';

const EnableEveryAlerts = ({ modelStore, id, className }) => {
  const handleChange = async props => {
    const checked = props.target.checked;

    const config = { ...modelStore.user.config, every_alert: checked };
    modelStore.user = await feathers.service('users').patch(id, {
      action: 'config',
      config,
    });
  };

  return (
    <Switch
      className={className}
      checked={modelStore.user.config.every_alert}
      onChange={handleChange}
    />
  );
};

export default compose(inject('modelStore'), observer)(EnableEveryAlerts);
