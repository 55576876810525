import React from 'react';
import { withFormik, Form } from 'formik';
import feathers from 'services/feathers';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import { openSnackbar } from 'components/common/Notifier';

const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  submit: {
    marginTop: 20,
    alignSelf: 'center',
  },
});

const AddOrganization = ({
  handleSubmit,
  classes,
  errors,
  isSubmitting,
  handleChange,
  values,
}) => {
  return (
    <Form className={classes.form} onSubmit={handleSubmit}>
      <TextField
        id="name"
        label="Nom"
        required
        value={values.name}
        onChange={handleChange}
        error={!!errors._form || !!errors.name}
        helperText={errors.name ? errors.name : ''}
        margin="normal"
      />
      {errors._form && (
        <FormHelperText id="organization-create-error" error>
          {errors._form}
        </FormHelperText>
      )}
      <Button
        className={classes.submit}
        type="submit"
        variant="contained"
        color="primary"
        disabled={isSubmitting}
      >
        Create
      </Button>
    </Form>
  );
};

export default compose(
  withRouter,
  inject('authenticationStore'),
  inject('modelStore'),
  withStyles(styles),
  withFormik({
    mapPropsToValues: () => ({
      name: '',
    }),
    handleSubmit: async (
      values,
      {
        setSubmitting,
        setErrors,
        props: { history, modelStore, onSubmitSuccess },
      },
    ) => {
      try {
        await feathers.service('organizations').create(values);
        modelStore.organizations = await feathers
          .service('organizations')
          .find();

        history.push('/dashboard/organizations');
        openSnackbar({
          message: 'Nouvelle organisation crée',
          variant: 'success',
        });
        await onSubmitSuccess();
      } catch (e) {
        setErrors({ _form: e.message });
      }
      setSubmitting(false);
    },

    displayName: 'CreateOrgnizationForm',
  }),
  observer,
)(AddOrganization);
