import React from 'react';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import feathers from 'services/feathers';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { observe } from 'mobx';
import { Link, withRouter } from 'react-router-dom';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Link as MUILink,
  Paper,
  Avatar,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import PasswordField from 'material-ui-password-field';
import { withFormik } from 'formik';
import Background from 'components/common/Background';

const styles = theme => ({
  main: {
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    width: 'fit-content',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(8),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  loading: {
    margin: 'auto',
    marginTop: theme.spacing(2),
  },
  resetPassword: {
    marginTop: 25,
  },
});

class Login extends React.Component {
  componentDidMount() {
    // Force logout
    if (this.props.authenticationStore.authenticated !== false) {
      const disposeObserver = observe(
        this.props.authenticationStore,
        'authenticated',
        ({ oldValue, newValue }) => {
          if (newValue === true) {
            feathers.logout();
          }
          if (newValue === false) {
            disposeObserver();
          }
        },
        true,
      );
    }
  }
  render() {
    const {
      handleChange,
      handleSubmit,
      classes,
      errors,
      isSubmitting,
      values,
      authenticationStore: { authenticated },
    } = this.props;

    if (authenticated === null) {
      return null;
    }

    return (
      <>
        <Background />
        <main className={classes.main}>
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Se connecter
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
              <TextField
                margin="normal"
                fullWidth
                error={!!errors._form}
                id="email"
                name="email"
                // autoComplete="email"
                // autoFocus
                value={values.email}
                onChange={handleChange}
                label="Email"
              />

              <FormControl fullWidth>
                <InputLabel htmlFor="password">Mot de passe</InputLabel>
                <PasswordField
                  margin="normal"
                  required
                  error={!!errors._form}
                  id="password"
                  // autoComplete="current-passord"
                  // autoFocus
                  value={values.password}
                  onChange={handleChange}
                  hintText="Mot de passe"
                />
              </FormControl>
              {errors._form && (
                <FormHelperText id="login" error>
                  {errors._form}
                </FormHelperText>
              )}
              <FormHelperText
                className={classes.resetPassword}
                id="my-helper-text"
              >
                <MUILink component={Link} to="/reset-password">
                  Mot de passe oublié ?
                </MUILink>
              </FormHelperText>
              {isSubmitting ? (
                <CircularProgress size={30} className={classes.loading} />
              ) : (
                <Button
                  className={classes.submit}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Se connecter
                </Button>
              )}
            </form>
          </Paper>
        </main>
      </>
    );
  }
}

export default compose(
  withRouter,
  inject('authenticationStore'),
  inject('modelStore'),
  withStyles(styles),
  withFormik({
    mapPropsToValues: () => ({
      email: '',
      password: '',
    }),
    handleSubmit: async (
      { email, password },
      { setSubmitting, setErrors, props: { history, authenticationStore } },
    ) => {
      /*
        We rely on observing authenticationStore.authenticated because it allows to push
        the new state after model is loaded
       */
      const disposeObserver = observe(
        authenticationStore,
        'authenticated',
        ({ newValue }) => {
          if (newValue) {
            history.push('/dashboard');
            disposeObserver();
          }
        },
      );
      try {
        await feathers.authenticate({
          strategy: 'local',
          email: email.toLowerCase(),
          password,
        });
      } catch (e) {
        setErrors({ _form: e.message });
        disposeObserver();
        setSubmitting(false);
      }
    },

    displayName: 'LoginForm',
  }),
  observer,
)(Login);
