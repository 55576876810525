import React from 'react';
import GroupIcon from '@material-ui/icons/Group';
import List from '@material-ui/core/List';
import withStyles from '@material-ui/core/styles/withStyles';
import MUIDrawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';
import BarChartIcon from '@material-ui/icons/BarChart';
import HistoryIcon from '@material-ui/icons/History';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Divider from '@material-ui/core/Divider';
import { compose } from 'recompose';
import { Link, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

const drawerWidth = 240;

const styles = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
});

const RouterListItem = withRouter(
  ({ history, key, to, disabled, children, pathname, ...props }) => (
    <ListItem
      key={key}
      to={to}
      disabled={disabled}
      children={children}
      button
      component={Link}
      selected={history.location.pathname === to}
      {...props}
    />
  ),
);

const Organizations = () => (
  <RouterListItem key={'organizations'} to="/dashboard/organizations">
    <ListItemIcon>
      <GroupIcon />
    </ListItemIcon>
    <ListItemText primary={'Organisations'} />
  </RouterListItem>
);

const Settings = () => (
  <RouterListItem key={'settings'} to="/dashboard/settings">
    <ListItemIcon>
      <SettingsIcon />
    </ListItemIcon>
    <ListItemText primary={'Paramètres'} />
  </RouterListItem>
);

const Advisors = () => (
  <RouterListItem key={'advisors'} to="/dashboard/advisors">
    <ListItemIcon>
      <GroupIcon />
    </ListItemIcon>
    <ListItemText primary={'Utilisateurs'} />
  </RouterListItem>
);

const Archive = () => (
  <RouterListItem key={'archive'} to="/dashboard/archive">
    <ListItemIcon>
      <HistoryIcon />
    </ListItemIcon>
    <ListItemText primary={'Archive'} />
  </RouterListItem>
);

const Waiting = () => (
  <RouterListItem key={'waiting'} to="/dashboard/waiting">
    <ListItemIcon>
      <HourglassEmptyIcon />
    </ListItemIcon>
    <ListItemText primary={'Visiteurs'} />
  </RouterListItem>
);

const Statistics = () => (
  <RouterListItem key={'statistics'} to="/dashboard/statistics" disabled>
    <ListItemIcon>
      <BarChartIcon />
    </ListItemIcon>
    <ListItemText primary={'Statistiques'} />
  </RouterListItem>
);

const Live = ({ organization_id }) => (
  <RouterListItem key={'live'} to={`/live/${organization_id}`} target="_blank">
    <ListItemIcon>
      <ExitToAppIcon />
    </ListItemIcon>
    <ListItemText primary={'Lancer la borne'} />
  </RouterListItem>
);

const Drawer = ({ classes, user: { type, organization_id } }) => {
  return (
    <MUIDrawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.toolbar} />
      <List>
        {type === 'SUPER_ADMIN' && <Organizations />}
        {['SUPERVISOR', 'ADMIN'].includes(type) && (
          <>
            <Settings />
            <Advisors />
            <Divider />
            <Archive />
            <Waiting />
            <Divider />
            <Statistics />
            <Divider />
            <Live organization_id={organization_id} />
          </>
        )}
        {type === 'ADVISOR' && (
          <>
            {/* <Archive /> */}
            <Waiting />
            <Divider />
            <Statistics />
            <Divider />
            <Live organization_id={organization_id} />
          </>
        )}
      </List>
    </MUIDrawer>
  );
};

export default compose(
  withRouter,
  inject('authenticationStore'),
  inject('modelStore'),
  withStyles(styles),
  observer,
)(Drawer);
