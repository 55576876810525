import feathers from '@feathersjs/feathers';
import auth from '@feathersjs/authentication-client';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';

import config from 'config';

const app = feathers();

const socket = io(config.server);
app.configure(socketio(socket, { timeout: 15000 }));

app.configure(
  auth({
    storage: window.localStorage,
  }),
);

export default app;
