let config = {
  server: 'http://localhost:3030',
};

if (process.env.REACT_APP_ENV === 'production') {
  config = {
    ...config,
    server: 'https://xifab-prod.herokuapp.com',
  };
}
if (process.env.REACT_APP_ENV === 'staging') {
  config = {
    ...config,
    server: 'https://xifab-staging.herokuapp.com',
  };
}

export default config;
