import React from 'react';
import feathers from 'services/feathers';
import { inject, observer } from 'mobx-react';
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Button,
  Switch,
  withStyles,
  InputLabel,
} from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { openSnackbar } from 'components/common/Notifier';
import { compose } from 'recompose';
import dayjs from 'dayjs';
import locale from 'dayjs/locale/fr';
import StatusIcon from 'components/common/StatusIcon';
import SectionTitle from 'components/common/SectionTitle';
import EnableAdvisorSwitch from './common/EnableAdvisorSwitch';
import EnableEveryAlerts from './common/EnableEveryAlerts';

const styles = theme => ({
  noVisitor: {
    marginTop: '2rem',
    textAlign: 'center',
    color: grey[500],
  },
  table: {
    minWidth: 800,
  },
  switch: {
    marginBottom: 30,
  },
  seeAll: {
    fontSize: '0.5em',
    color: grey[500],
  },
});

class Waiting extends React.Component {
  state = {
    visits: null,
    seeAll: false,
  };

  componentDidMount = async () => {
    this.fetchVisits();
    feathers.service('visits').on('created', this.fetchVisits);
    // Fetch visits on focus
    window.addEventListener('focus', this.fetchVisits);

    // Fetch visits every 2 hours
    this.interval = setInterval(this.fetchVisits, 1000 * 60 * 60 * 2);
  };

  componentWillUnmount() {
    feathers.service('visits').removeListener('created', this.fetchVisits);
  }

  onClose = id => async () => {
    if (
      !window.confirm(
        'Ce visiteur sera considéré comme traité\n\nÊtes-vous sûr?',
      )
    ) {
      return null;
    }
    const { name } = await feathers
      .service('visits')
      .patch(id, { closed: true });
    openSnackbar({
      message: `Le visiteur "${name}" a été traité`,
      variant: 'success',
    });
    this.fetchVisits();
  };

  fetchVisits = async () => {
    const visits = this.state.seeAll
      ? await feathers.service('visits').find({
          query: {
            $limit: -1,
            closed: false,
          },
        })
      : [
          ...(await feathers.service('visits').find({
            query: {
              $limit: -1,
              closed: false,
              advisor_id: this.props.modelStore.user.id,
            },
          })),
          ...(await feathers.service('visits').find({
            query: {
              $limit: -1,
              closed: false,
              advisor_id: 'null',
            },
          })),
        ];
    this.setState({ visits });
  };

  render() {
    const { classes, modelStore } = this.props;

    if (this.state.visits === null || modelStore.organization === null)
      return null;
    if (!this.state.visits) return <div>Pas de visiteurs actuellement</div>;

    const visits = this.state.visits.slice();
    visits.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

    return (
      <>
        <SectionTitle>Paramètres</SectionTitle>
        <InputLabel>Disponible</InputLabel>
        <EnableAdvisorSwitch
          id={modelStore.user.id}
          className={classes.switch}
        />
        <>
          <InputLabel>Notifications</InputLabel>
          <div style={{ marginBottom: 30, marginTop: 10 }}>
            {typeof Notification !== 'undefined' &&
            Notification.permission === 'default' ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => Notification.requestPermission()}
              >
                Activer
              </Button>
            ) : (
              <span
                style={{
                  color: grey[500],
                }}
              >
                {typeof Notification === 'undefined'
                  ? 'Les notifications ne sont pas disponibles dans votre navigateur'
                  : Notification.permission === 'granted'
                  ? 'Activées'
                  : 'Veuillez activer les notifications dans les paramètres de votre navigateur'}
              </span>
            )}
          </div>
        </>

        {['ADMIN', 'SUPERVISOR'].includes(modelStore.user.type) && (
          <>
            <InputLabel>Etre alerté pour toute visite</InputLabel>
            <EnableEveryAlerts
              id={modelStore.user.id}
              className={classes.switch}
            />
          </>
        )}
        <>
          <SectionTitle>
            Visiteurs en attente
            {['ADMIN', 'SUPERVISOR'].includes(modelStore.user.type) && (
              <div className={classes.seeAll}>
                voir tous les visiteurs{' '}
                <Switch
                  checked={this.state.seeAll}
                  onChange={e =>
                    this.setState(
                      { seeAll: e.target.checked },
                      this.fetchVisits,
                    )
                  }
                />
              </div>
            )}
          </SectionTitle>

          {!visits.length && (
            <div className={classes.noVisitor}>
              Il n'y a pas de visiteur en attente
            </div>
          )}

          {!!visits.length && (
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Nom</TableCell>
                  {(modelStore.organization.config.deuxieme_champs_activated ||
                    modelStore.organization.config
                      .deuxieme_champs_appointment_activated) && (
                    <>
                      <TableCell>Champs 2</TableCell>
                    </>
                  )}
                  <TableCell>Date</TableCell>
                  {modelStore.organization.config.appointment.activated && (
                    <>
                      <TableCell>Avec rendez-vous</TableCell>
                      {this.state.seeAll && <TableCell>Conseiller</TableCell>}
                    </>
                  )}
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visits.map(
                  ({ id, name, created_at, advisor_id, deuxieme_champs }) => {
                    const advisors =
                      advisor_id && modelStore.advisors
                        ? modelStore.advisors.find(e => e.id === advisor_id)
                        : null;

                    return (
                      <TableRow key={id}>
                        <TableCell>{name}</TableCell>
                        {(modelStore.organization.config
                          .deuxieme_champs_activated ||
                          modelStore.organization.config
                            .deuxieme_champs_appointment_activated) && (
                          <TableCell>{deuxieme_champs}</TableCell>
                        )}
                        <TableCell>
                          {dayjs(created_at).format(locale.formats.LLLL)}
                        </TableCell>
                        {modelStore.organization.config.appointment
                          .activated && (
                          <>
                            <TableCell>
                              <StatusIcon checked={advisor_id} />
                            </TableCell>
                            {this.state.seeAll && (
                              <TableCell>
                                {advisor_id
                                  ? `${advisors.firstname} ${advisors.lastname}`
                                  : ''}
                              </TableCell>
                            )}
                          </>
                        )}
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.onClose(id)}
                          >
                            Clore
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  },
                )}
              </TableBody>
            </Table>
          )}
        </>
      </>
    );
  }
}

export default compose(
  withStyles(styles),
  inject('modelStore'),
  observer,
)(Waiting);
