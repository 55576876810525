import React from 'react';
import { withFormik, Form } from 'formik';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import feathers from 'services/feathers';

import withStyles from '@material-ui/core/styles/withStyles';
import {
  InputLabel,
  Button,
  FormHelperText,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

import { openSnackbar } from 'components/common/Notifier';

const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 300,
  },
  submit: {
    marginTop: 20,
    alignSelf: 'center',
  },
});

const keyToLabel = {
  email: 'Email',
  firstname: 'Prénom',
  lastname: 'Nom',
};

const AddUser = ({ classes, errors, isSubmitting, handleChange, values }) => {
  return (
    <Form className={classes.form}>
      {['email', 'firstname', 'lastname'].map(key => (
        <TextField
          key={key}
          id={key}
          label={keyToLabel[key]}
          required
          value={values[key]}
          onChange={handleChange}
          error={!!errors._form || !!errors[key]}
          helperText={errors[key] ? errors[key] : ''}
          margin="normal"
        />
      ))}
      <FormControl
        margin="normal"
        key="type"
        required
        error={!!errors._form || !!errors.type}
      >
        <InputLabel htmlFor="type">Type</InputLabel>
        <Select
          value={values.type}
          onChange={handleChange}
          id="type"
          name="type"
        >
          <MenuItem value="">
            <em>Aucun</em>
          </MenuItem>
          <MenuItem value="ADMIN">Admin</MenuItem>
          <MenuItem value="SUPERVISOR">Superviseur</MenuItem>
          <MenuItem value="ADVISOR">Conseiller</MenuItem>
        </Select>
        {errors.type && <FormHelperText>{errors.type}</FormHelperText>}
      </FormControl>
      {errors._form && <FormHelperText error>{errors._form}</FormHelperText>}
      <Button
        className={classes.submit}
        type="submit"
        variant="contained"
        color="primary"
        disabled={isSubmitting}
      >
        Créer
      </Button>
    </Form>
  );
};

export default compose(
  withRouter,
  inject('authenticationStore'),
  inject('modelStore'),
  withStyles(styles),
  withFormik({
    mapPropsToValues: () => ({
      firstname: '',
      lastname: '',
      email: '',
      type: '',
    }),
    handleSubmit: async (
      values,
      {
        setSubmitting,
        setErrors,
        resetForm,
        props: { organizationId, onSubmitSuccess },
      },
    ) => {
      try {
        const userValues = organizationId
          ? { organization_id: organizationId, ...values }
          : values;
        const data = await feathers.service('users').create(userValues);
        resetForm();
        openSnackbar({
          message: "L'utilisateur a été créé",
          variant: 'success',
        });
        await onSubmitSuccess(data);
      } catch (e) {
        if (e.errors && Object.keys(e.errors).length) {
          setErrors({ ...e.errors });
        } else {
          setErrors({ _form: e.message });
        }
      }
      setSubmitting(false);
    },

    displayName: 'AddUserForm',
  }),
  observer,
)(AddUser);
