import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import { Link as RouterLink } from 'react-router-dom';
import feathers from 'services/feathers';
import withStyles from '@material-ui/core/styles/withStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import CallMadeIcon from '@material-ui/icons/CallMade';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Icon,
  Link,
} from '@material-ui/core';
import AddOrganization from './AddOrganization';
import FormDialog from 'components/common/FormDialog';
import SectionTitle from 'components/common/SectionTitle';
import { openSnackbar } from 'components/common/Notifier';
import NoDataScreen from 'components/common/NoDataScreen';

const styles = theme => ({
  addButton: {
    margin: '30px 0',
  },
  title: {
    margin: '30px auto 20px auto',
  },
  table: {
    minWidth: 700,
  },
});

class List extends Component {
  state = {
    organizations: null,
  };

  componentDidMount = async () => {
    this.fetchOrganizations();
  };

  fetchOrganizations = async () => {
    const organizations = await feathers.service('organizations').find();
    this.setState({ organizations });
  };

  onDelete = id => async () => {
    if (
      !window.confirm(
        'Tous les utilisateurs de cette organisations vont aussi être supprimés\n\n Êtes-vous sûr?',
      )
    )
      return;
    await feathers.service('organizations').remove(id);
    await this.fetchOrganizations();

    openSnackbar({
      message: "L'organisation a été supprimée",
      variant: 'success',
    });
  };

  render() {
    const { classes } = this.props;
    const { organizations } = this.state;
    if (!organizations) return null;

    return (
      <div>
        <SectionTitle>Organisations</SectionTitle>
        <Typography className={classes.title} variant="h6">
          Ajouter une organisation
        </Typography>
        <FormDialog
          label="Create an organization"
          title="New organization"
          render={close => (
            <AddOrganization
              onSubmitSuccess={async () => {
                await this.fetchOrganizations();
                close();
              }}
            />
          )}
        />
        <Typography className={classes.title} variant="h6">
          Liste des organisations
        </Typography>
        {!organizations.length && (
          <NoDataScreen>Il n'y a pas encore d'organisation</NoDataScreen>
        )}
        {!!organizations.length && (
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>Live</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organizations.map(({ id, name }) => (
                <TableRow key={id}>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      to={`/dashboard/organizations/${id}`}
                    >
                      {id}
                    </Link>
                  </TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>
                    <Link component={RouterLink} to={`/live/${id}`}>
                      <Icon color="primary">
                        <CallMadeIcon />
                      </Icon>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <IconButton color="secondary" onClick={this.onDelete(id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  inject('modelStore'),
  observer,
)(List);
