import React from 'react';
import { Link } from 'react-router-dom';
import logo from 'resources/logo.svg';
import Button from '@material-ui/core/Button';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    alignItems: 'center',
    height: '100vh',
    justifyContent: 'center',
  },
  logo: {
    margin: '0 auto',
    height: '30%',
  },
  button: {
    margin: '2% 0 20% 0',
    width: 'fit-content',
  },
});

const Home = ({ classes }) => {
  return (
    <section className={classes.section}>
      <img src={logo} alt="" className={classes.logo} />
      <Button
        component={Link}
        to="/dashboard"
        variant="contained"
        color="primary"
        className={classes.button}
      >
        Go to dashboard
      </Button>
    </section>
  );
};

export default compose(withStyles(styles))(Home);
