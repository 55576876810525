import React from 'react';
import { withFormik, Form } from 'formik';
import { compose, lifecycle } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import feathers from 'services/feathers';

import withStyles from '@material-ui/core/styles/withStyles';
import {
  InputLabel,
  Button,
  FormHelperText,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

import { openSnackbar } from 'components/common/Notifier';

const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 300,
  },
  submit: {
    marginTop: 20,
    alignSelf: 'center',
  },
});

const keyToLabel = {
  email: 'email',
  firstname: 'Prénom',
  lastname: 'Nom',
};

const EditUser = ({
  classes,
  errors,
  isSubmitting,
  handleChange,
  values,
  modelStore,
}) => {
  if (!values) return null;
  return (
    <Form className={classes.form}>
      {['firstname', 'lastname'].map(key => (
        <TextField
          key={key}
          id={key}
          label={keyToLabel[key]}
          required
          value={values[key]}
          onChange={handleChange}
          error={!!errors._form || !!errors[key]}
          helperText={errors[key] ? errors[key] : ''}
          margin="normal"
        />
      ))}
      {['type'].map(key => (
        <FormControl
          margin="normal"
          key="type"
          required
          error={!!errors._form || !!errors.type}
        >
          <InputLabel htmlFor="type">Type</InputLabel>
          <Select
            value={values.type}
            onChange={handleChange}
            id="type"
            name="type"
          >
            {modelStore.user.type !== 'SUPERVISOR' && (
              <MenuItem value="ADMIN">Admin</MenuItem>
            )}
            <MenuItem value="SUPERVISOR">Superviseur</MenuItem>
            <MenuItem value="ADVISOR">Conseiller</MenuItem>
          </Select>
          {errors.type && <FormHelperText>{errors.type}</FormHelperText>}
        </FormControl>
      ))}
      {errors._form && <FormHelperText error>{errors._form}</FormHelperText>}
      <Button
        className={classes.submit}
        type="submit"
        variant="contained"
        color="primary"
        disabled={isSubmitting}
      >
        Valider
      </Button>
    </Form>
  );
};

export default compose(
  withRouter,
  inject('authenticationStore'),
  inject('modelStore'),
  withStyles(styles),
  lifecycle({
    async componentDidMount() {
      const user = await feathers.service('users').get(this.props.user_id);

      this.setState({ user });
    },
  }),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: props =>
      props.user
        ? {
            firstname: props.user.firstname,
            lastname: props.user.lastname,
            type: props.user.type,
          }
        : null,
    handleSubmit: async (
      values,
      { setSubmitting, setErrors, resetForm, props: { user, onSubmitSuccess } },
    ) => {
      try {
        const data = await feathers.service('users').patch(user.id, values);
        openSnackbar({
          message: "L'utilisateur a été édité",
          variant: 'success',
        });
        await onSubmitSuccess(data);
        resetForm();
      } catch (e) {
        if (e.errors && Object.keys(e.errors).length) {
          setErrors({ ...e.errors });
        } else {
          setErrors({ _form: e.message });
        }
      }
      setSubmitting(false);
    },

    displayName: 'EditUserForm',
  }),
  observer,
)(EditUser);
