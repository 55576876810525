import React from 'react';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import * as yup from 'yup';
import qs from 'query-string';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import feathers from 'services/feathers';
import {
  FormHelperText,
  Paper,
  Avatar,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFormik } from 'formik';
import PasswordField from 'material-ui-password-field';
import Background from 'components/common/Background';

const styles = theme => ({
  main: {
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    width: 'fit-content',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(8),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  loading: {
    margin: 'auto',
    marginTop: theme.spacing(2),
  },
});

const NewPassword = ({
  handleChange,
  handleSubmit,
  classes,
  errors,
  isSubmitting,
  values,
  authenticationStore: { authenticated },
}) => {
  return (
    <>
      <Background />
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Nouveau mot de passe
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <PasswordField
              margin="normal"
              required
              fullWidth
              error={!!errors.password}
              label="Mot de passe"
              id="password"
              name="password"
              autoComplete="password"
              autoFocus
              onChange={handleChange}
              value={values.password}
            />
            {errors.password && (
              <FormHelperText id="password-helper-text" error>
                {errors.password}
              </FormHelperText>
            )}
            {errors._form && (
              <FormHelperText id="new-password" error>
                {errors._form}
              </FormHelperText>
            )}
            {isSubmitting ? (
              <CircularProgress size={30} className={classes.loading} />
            ) : (
              <Button
                className={classes.submit}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Valider
              </Button>
            )}
          </form>
        </Paper>
      </main>
    </>
  );
};

export default compose(
  withRouter,
  inject('authenticationStore'),
  withStyles(styles),
  withFormik({
    mapPropsToValues: () => ({
      password: '',
    }),
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
          'Le mot de passe doit contenir au moins une majuscule, un chiffre et mesurer au minimum 8 caractères',
        )
        .required(),
    }),
    validateOnChange: false,
    handleSubmit: async (
      { password },
      {
        setSubmitting,
        setErrors,
        resetForm,
        props: { history, authenticationStore, location },
      },
    ) => {
      try {
        const { user_id, reset_token } = qs.parse(location.search);
        feathers.logout();
        authenticationStore.authenticated = false;
        await feathers
          .service('users')
          .patch(user_id, { action: 'new-password', reset_token, password });
        history.push('/login');
      } catch (e) {
        resetForm();
        setErrors({ _form: 'Une erreur est survenue' });
      }
      setSubmitting(false);
    },

    displayName: 'NewPasswordForm',
  }),
  observer,
)(NewPassword);
