import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

function FormDialog({ render, label, title, renderButton }) {
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      {renderButton ? (
        renderButton({ open: handleClickOpen })
      ) : (
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          {label}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>{render(handleClose)}</DialogContent>
      </Dialog>
    </>
  );
}

export default FormDialog;
