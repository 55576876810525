import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import { Route, Switch } from 'react-router-dom';

import Organizations from './pages/Organizations';
import Settings from './pages/Settings';
import Advisors from './pages/Advisors';
import Archive from './pages/Archive';
import Waiting from './pages/Waiting';
import Drawer from './Drawer';
import AppBar from './AppBar';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
});

const labels = {
  SUPER_ADMIN: 'super admin',
  ADMIN: 'admin',
  SUPERVISOR: 'superviseur',
  ADVISOR: 'advisor',
};

const Dashboard = ({
  classes,
  authenticationStore: { authenticated },
  modelStore: { user, organization },
}) => {
  if (user.type === 'LIVE') {
    return <Redirect to="/login" />;
  }

  return (
    <div className={classes.root}>
      <AppBar
        title={`${user.firstname} ${user.lastname}`}
        subtitle={
          labels[user.type] + (organization ? ` — ${organization.name}` : '')
        }
      />
      <Drawer user={user} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <>
          {user.type === 'SUPER_ADMIN' && (
            <Switch>
              <Route
                path="/dashboard/organizations"
                component={Organizations}
              />
              <Redirect from="/dashboard" exact to="/dashboard/organizations" />
            </Switch>
          )}
          {['ADMIN', 'SUPERVISOR'].includes(user.type) && (
            <Switch>
              <Route path="/dashboard/settings" component={Settings} />
              <Route path="/dashboard/advisors" component={Advisors} />
              <Route path="/dashboard/waiting" component={Waiting} />
              <Route path="/dashboard/archive" component={Archive} />
              <Redirect from="/dashboard" exact to="/dashboard/settings" />
            </Switch>
          )}
          {user.type === 'ADVISOR' && (
            <Switch>
              <Route path="/dashboard/waiting" component={Waiting} />
              <Route path="/dashboard/archive" component={Archive} />
              <Redirect from="/dashboard" exact to="/dashboard/waiting" />
            </Switch>
          )}
        </>
      </main>
    </div>
  );
};

export default compose(
  withStyles(styles),
  inject('authenticationStore'),
  inject('modelStore'),
  observer,
)(Dashboard);
