import React, { Component } from 'react';
import {
  withStyles,
  TextField as MuiTextField,
  CircularProgress,
} from '@material-ui/core';
import Button from './Button';

const styles = theme => ({
  button: {
    fontSize: '1.8em',
    height: 100,
    width: 300,
    margin: 'auto',
    marginBottom: 20,
  },
  input: {
    margin: '-30px 10% 5% 10%',
    '& input': {
      height: 60,
      fontSize: '2em',
      textAlign: 'center',
    },
  },
  ghostInput: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    background: 'none',
    border: '0',
    outline: 'none',
    opacity: 0,
  },
});

class TextField extends Component {
  state = {
    touched: false,
  };
  render() {
    const { touched } = this.state;
    const { value, setValue, submit, submitting, classes } = this.props;
    return (
      <>
        {touched && <MuiTextField className={classes.input} value={value} />}
        <input
          className={classes.ghostInput}
          onChange={e => {
            setValue(e.target.value);
          }}
          onSelect={() => {
            this.setState({ touched: true });
          }}
          autoFocus
        />
        {touched && (
          <Button
            className={classes.button}
            disabled={!value || submitting}
            action={submit}
          >
            {submitting ? <CircularProgress color="primary" /> : 'OK'}
          </Button>
        )}
      </>
    );
  }
}

export default withStyles(styles)(TextField);
