import React from 'react';
// import withStyles from '@material-ui/core/styles/withStyles';
import { inject, observer } from 'mobx-react';
import { Switch } from '@material-ui/core';
import { compose } from 'recompose';
import feathers from 'services/feathers';

const EnableAdvisorSwitch = ({ modelStore, id, className }) => {
  const enabledAdvisors = modelStore.organization.enabled_advisors;

  const handleChange = async props => {
    const checked = props.target.checked;
    const nextEnabledAdvisors = enabledAdvisors.slice();
    if (checked && !nextEnabledAdvisors.includes(id)) {
      nextEnabledAdvisors.push(id);
    }
    if (!checked && nextEnabledAdvisors.includes(id)) {
      nextEnabledAdvisors.splice(nextEnabledAdvisors.indexOf(id), 1);
    }
    modelStore.organization = await feathers
      .service('organizations')
      .patch(modelStore.organization.id, {
        enabled_advisors: nextEnabledAdvisors,
      });
  };

  return (
    <Switch
      className={className}
      checked={enabledAdvisors.includes(id)}
      onChange={handleChange}
    />
  );
};

export default compose(
  //   withStyles(styles),
  inject('modelStore'),
  observer,
)(EnableAdvisorSwitch);
