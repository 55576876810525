import React from 'react';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import * as yup from 'yup';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import feathers from 'services/feathers';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFormik, Form } from 'formik';
import Background from 'components/common/Background';

const styles = theme => ({
  main: {
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    width: 'fit-content',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(8),
  },
  avatar: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(7),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  loading: {
    margin: 'auto',
    marginTop: theme.spacing(2),
  },
});

const ResetPassword = ({
  handleChange,
  classes,
  errors,
  isSubmitting,
  values,
  status,
  authenticationStore: { authenticated },
}) => {
  return (
    <>
      <Background />
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="p" variant="subtitle1">
            {!status &&
              'Tapez votre addresse email pour recevoir un nouveau mot de passe'}
            {status &&
              'Un email permettant de renseigner un nouveau mot de passe vous a été envoyé'}
          </Typography>
          {!status && (
            <Form className={classes.form}>
              <TextField
                margin="normal"
                required
                fullWidth
                error={!!errors._form}
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                autoFocus
                value={values.email}
                onChange={handleChange}
                label="Email"
              />
              {errors._form && (
                <FormHelperText id="new-password" error>
                  {errors._form}
                </FormHelperText>
              )}
              {isSubmitting ? (
                <CircularProgress size={30} className={classes.loading} />
              ) : (
                <Button
                  className={classes.submit}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Valider
                </Button>
              )}
            </Form>
          )}
        </Paper>
      </main>
    </>
  );
};

export default compose(
  withRouter,
  inject('authenticationStore'),
  withStyles(styles),
  withFormik({
    mapPropsToValues: () => ({
      email: '',
    }),
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email()
        .required(),
    }),
    validateOnChange: false,
    handleSubmit: async (
      { email },
      {
        setSubmitting,
        setErrors,
        resetForm,
        setStatus,
        props: { history, authenticationStore, location },
      },
    ) => {
      try {
        await feathers
          .service('users')
          .patch(null, { action: 'reset-password', email });
        setStatus(true);
      } catch (e) {
        resetForm();
        setErrors({ _form: 'Une erreur est survenue' });
      }
      setSubmitting(false);
    },

    displayName: 'ResetPasswordForm',
  }),
  observer,
)(ResetPassword);
