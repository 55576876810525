import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';

const RenderedComponent = compose(
  inject('authenticationStore'),
  observer,
)(({ Component, authenticationStore, ...props }) => {
  if (authenticationStore.authenticated === true) {
    return <Component {...props} />;
  }

  if (authenticationStore.authenticated === false) {
    return <Redirect to="/login" />;
  }

  return null;
});

const AuthenticatedRoute = ({
  component: Component,
  authenticationStore,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => <RenderedComponent {...props} Component={Component} />}
  />
);

export default AuthenticatedRoute;
