import React from 'react';
import MUIButton from '@material-ui/core/Button';

const Button = ({
  children,
  action,
  noPreventDefault,
  className,
  disabled,
}) => (
  <MUIButton
    className={className}
    disabled={disabled}
    size="large"
    variant="contained"
    color="primary"
    onClick={() => {
      action();
    }}
    onTouchEnd={e => {
      if (!noPreventDefault) {
        e.preventDefault();
      }
      action();
    }}
  >
    {children}
  </MUIButton>
);

export default Button;
