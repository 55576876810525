import React from 'react';
import { compose } from 'recompose';
import feathers from 'services/feathers';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import {
  withStyles,
  Typography,
  Paper,
  Link,
  CircularProgress,
  Button,
} from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import Idle from 'utils/idle';
import Appointment from './Appointment';
import AdvisorPicker from './AdvisorPicker';
import Confirmation from './Confirmation';
import TextField from './TextField';

const styles = theme => ({
  wrapper: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 30,
    margin: 30,
    marginBottom: '23%',
  },
  title: {
    marginBottom: 30,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  returnBtn: {
    margin: 'auto',
    fontSize: '1.7em',
    height: 50,
    width: 150,
    zIndex: 2,
  },
  background: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    zIndex: -1,
  },
  loading: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    flexDirection: 'column',
  },
  progress: {
    marginBottom: 30,
  },
});

class Container extends React.Component {
  /**
   * Steps
   * 0 - "J'ai rendez-vous/Je n'ai pas rendez-vous"
   * 1 - Advisor picker
   * 2 - Name form
   * 3 - Confirmation
   */
  state = {
    step: null,
    advisors: null,
    organization: null,
    name: '',
    deuxieme_champs: '',
    advisor: null,
    submitting: false,
    hasAppointment: false,
  };

  componentDidMount = async () => {
    this.reset();

    feathers.service('organizations').on('patched', this.reset);

    const idle = new Idle({
      delay: 20 * 1000,
      onIdle: () => this.reset(),
    });
    idle.start();
  };

  componentWillUnmount() {
    feathers.service('organizations').removeListener('patched', this.reset);
  }

  reset = async () => {
    const { match } = this.props;

    try {
      const organization = await feathers
        .service('organizations')
        .get(match.params.organization_id);

      if (organization.config.appointment.activated) {
        const advisors = await feathers
          .service('users')
          .find({ query: { organization_id: organization.id } });

        this.setState({
          organization,
          step: 0,
          advisors: advisors.filter(({ id }) =>
            organization.enabled_advisors.includes(id),
          ),
          name: '',
          deuxieme_champs: '',
          advisor: null,
        });
      } else {
        this.setState({
          organization,
          step: 2,
          name: '',
          deuxieme_champs: '',
          advisor: null,
        });
      }
    } catch (e) {
      console.log('error, setting timeout');
    }
  };

  submit = async () => {
    // Allow debounce because of both use of onClick and onTouchEnd
    this.setState(async ({ submitting }) => {
      if (!submitting) {
        this.setState({ submitting: true }, async () => {
          try {
            await feathers.service('visits').create({
              name: this.state.name,
              deuxieme_champs: this.state.deuxieme_champs,
              advisor_id: this.state.advisor ? this.state.advisor.id : null,
              organization_id: this.state.organization.id,
            });
            this.setState({ step: 4, submitting: false });
          } catch (e) {
            window.alert('Une erreur est survenue, veuillez réessayer');
            this.setState({ step: 0, submitting: false });
          }
        });
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { organization, step, advisors, advisor } = this.state;

    if (
      !organization ||
      (organization.config.appointment.activated && !advisors)
    )
      return (
        <div className={classes.loading}>
          <CircularProgress size={50} className={classes.progress} />
          Chargement de de la borne…
        </div>
      );

    const {
      config: {
        assets,
        appointment,
        welcome_title,
        welcome_title_2,
        thank_you,
        label_nom,
        deuxieme_champs_activated,
        deuxieme_champs_label,
        deuxieme_champs_appointment_activated,
        deuxieme_champs_appointment_label,
      },
      name,
    } = organization;

    return (
      <div className={classes.wrapper}>
        <img src={assets.background} className={classes.background} alt="" />
        <Paper className={classes.innerWrapper}>
          <Typography variant="h2" className={classes.title}>
            {step === 0 && (
              <>
                {welcome_title}
                <br />
                {welcome_title_2}
              </>
            )}
            {step === 1 && (
              <>
                <Button
                  variant="contained"
                  // color="primary"
                  style={{
                    borderRadius: '50%',
                    width: '60px',
                    height: '60px',
                    minWidth: '60px',
                    padding: 0,
                    marginRight: '30px',
                  }}
                  onClick={() => {
                    this.setState({
                      advisor: null,
                      name: '',
                      deuxieme_champs: '',
                      hasAppointment: false,
                      step: 0,
                    });
                  }}
                >
                  <KeyboardArrowLeftIcon style={{ fontSize: '2rem' }} />
                </Button>
                {appointment.choose_advisor}
              </>
            )}
            {step === 2 &&
              (appointment.activated ? (
                label_nom
              ) : (
                <>
                  {welcome_title}
                  <br />
                  {welcome_title_2}
                  <br />
                  {label_nom}
                </>
              ))}
            {step === 3 &&
              (this.state.hasAppointment
                ? deuxieme_champs_appointment_label
                : deuxieme_champs_label)}
            {step === 4 &&
              (this.state.hasAppointment && appointment.thank_you
                ? appointment.thank_you
                : thank_you)}
          </Typography>
          {step === 0 && (
            <Appointment
              goToStep={step => {
                this.setState({ step, hasAppointment: step === 1 });
              }}
              name={name}
              config={appointment}
            />
          )}
          {step === 1 && (
            <AdvisorPicker
              advisors={[...advisors]}
              advisor={advisor}
              setAdvisor={advisor => this.setState({ advisor })}
              goToStep={step => this.setState({ step })}
            />
          )}
          {step === 2 && (
            <TextField
              submit={() =>
                (this.state.hasAppointment &&
                  deuxieme_champs_appointment_activated) ||
                (!this.state.hasAppointment && deuxieme_champs_activated)
                  ? this.setState({ step: 3 })
                  : this.submit()
              }
              setValue={name => this.setState({ name })}
              value={this.state.name}
              submitting={this.state.submitting}
            />
          )}
          {step === 3 && (
            <TextField
              submit={this.submit}
              setValue={deuxieme_champs => this.setState({ deuxieme_champs })}
              value={this.state.deuxieme_champs}
              submitting={this.state.submitting}
            />
          )}
          {step === 4 && <Confirmation reset={this.reset} />}
          {appointment.activated && step >= 2 && step <= 3 && (
            <Link
              component="button"
              onClick={() => {
                this.setState({
                  advisor: null,
                  name: '',
                  deuxieme_champs: '',
                  hasAppointment: false,
                  step: 0,
                });
              }}
              onTouchEnd={e => {
                e.preventDefault();
                this.setState({
                  advisor: null,
                  name: '',
                  deuxieme_champs: '',
                  step: 0,
                });
              }}
              className={classes.returnBtn}
            >
              Retour
            </Link>
          )}
        </Paper>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles),
  inject('authenticationStore'),
  inject('modelStore'),
  observer,
)(Container);
