import { decorate, observable } from 'mobx';
import feathers from 'services/feathers';
import playSound from 'utils/playSound';
import model from './model';

const handleNotification = async ({ advisor_id, name }) => {
  if (
    advisor_id === null ||
    advisor_id === model.user.id ||
    model.user.config.every_alert
  ) {
    if (window.location.pathname.indexOf('dashboard') !== -1) {
      const advisor =
        advisor_id === model.user.id
          ? model.user
          : advisor_id
          ? (model.advisors || []).find(e => e.id === advisor_id)
          : null;

      await playSound();
      if (
        typeof Notification !== 'undefined' &&
        Notification.permission === 'granted'
      ) {
        const notification = new Notification(`${name} vient d'arriver`, {
          body: `${
            advisor ? `${advisor.firstname} ${advisor.lastname}` : 'Pas de RDV'
          }`,
          silent: true,
        });
        setTimeout(() => {
          notification.close();
        }, 8000);
      }
    }
  }
};

class Authentication {
  authenticated = null;
  userId = null;

  constructor() {
    feathers.on('authenticated', async ({ accessToken }) => {
      const authentication = await feathers.get('authentication');
      model.user = authentication.user;
      await model.hydrate();
      this.authenticated = true;

      // Setup notifications
      if (['ADMIN', 'SUPERVISOR', 'ADVISOR'].includes(model.user.type)) {
        if (typeof Notification === 'undefined') return;
        if (Notification.permission !== 'granted') {
          Notification.requestPermission();
        }
        feathers.service('visits').on('created', handleNotification);
      }
    });

    feathers.on('logout', () => {
      if (
        model.user &&
        ['ADMIN', 'SUPERVISOR', 'ADVISOR'].includes(model.user.type)
      ) {
        feathers
          .service('visits')
          .removeListener('created', handleNotification);
      }
      this.authenticated = false;
      model.user = null;
      model.organization = null;
      model.organizations = null;
    });

    feathers.reAuthenticate().catch(e => {
      this.authenticated = false;
    });
  }
}

decorate(Authentication, {
  authenticated: observable,
  userId: observable,
});

const authentication = new Authentication();

export default authentication;
