import React from 'react';
import Button from './Button';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
    '& button': {
      margin: 30,
      fontSize: '1.5em',
      height: 130,
      width: 400,
    },
  },
});

const Appointment = ({ goToStep, classes, name, config }) => (
  <div className={classes.buttons}>
    <Button
      action={() => {
        goToStep(1);
      }}
    >
      {config.label_have ? config.label_have : "J'ai un rendez-vous"}
    </Button>
    <Button action={() => goToStep(2)}>
      {config.label_not_have
        ? config.label_not_have
        : "Je n'ai pas de rendez-vous"}
    </Button>
  </div>
);

export default withStyles(styles)(Appointment);
