import React from 'react';
import feathers from 'services/feathers';
import Papa from 'papaparse';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from '@material-ui/core';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import dayjs from 'dayjs';
import locale from 'dayjs/locale/fr';
import NoDataScreen from 'components/common/NoDataScreen';
import SectionTitle from 'components/common/SectionTitle';

const styles = theme => ({
  title: {
    display: 'flex',
    'align-items': 'stretch',
    'justify-content': 'space-between',
  },
  pagination: {
    margin: 'auto',
  },
});

class Archive extends React.Component {
  state = {
    visits: null,
  };
  componentDidMount = async () => {
    this.fetchVisits();
  };

  fetchVisits = async newPage => {
    const page = newPage != null ? newPage : this.getPage();
    const day = dayjs().subtract(page, 'day');
    const visits = await feathers.service('visits').find({
      query: {
        $sort: {
          closed_at: -1,
        },
        closed: true,
        closed_at: {
          $gt: day.startOf('day').toISOString(),
          $lt: day.endOf('day').toISOString(),
        },
      },
    });
    this.setState({ visits });
  };

  changePage(newPage) {
    this.props.history.push(`?page=${newPage}`);
    this.fetchVisits(newPage);
  }

  getPage() {
    const params = qs.parse(this.props.location.search);
    const page = params.page ? parseInt(params.page, 10) : 0;

    return page;
  }

  deleteHistory = async () => {
    if (
      !window.confirm(
        "L'ensemble de l'historique sera supprimé.\nÊtes-vous sûr ?",
      )
    ) {
      return;
    }

    await feathers.service('visits').remove(null);
    await this.fetchVisits();
  };

  downloadArchive = async () => {
    const users = await feathers.service('users').find();
    const visits = await feathers.service('visits').find();

    const result = visits.map(e => {
      const advisor = e.advisor_id
        ? users.find(u => u.id === e.advisor_id)
        : null;
      return {
        id: e.id,
        advisor: advisor ? `${advisor.firstname} ${advisor.lastname}` : '',
        created_at: dayjs(e.created_at).format(),
        name: e.name,
        deuxieme_champs: e.deuxieme_champs,
        closed: true,
        closed_at: e.closed_at ? dayjs(e.closed_at).format() : '',
      };
    });

    const csv = encodeURI(
      'data:text/csv;charset=utf-8,' + Papa.unparse(result),
    );
    const link = document.createElement('a');
    link.setAttribute('href', csv);
    link.setAttribute('download', `export-${dayjs().format()}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    const { visits } = this.state;
    const { classes, modelStore } = this.props;

    if (visits === null) return null;

    const page = this.getPage();
    const day = dayjs().subtract(page, 'day');

    return (
      <>
        <SectionTitle>
          Archive
          {modelStore.user.type === 'ADMIN' && (
            <Button color="secondary" onClick={this.deleteHistory}>
              Supprimer l'historique
            </Button>
          )}
          {['ADMIN', 'SUPERVISOR'].includes(modelStore.user.type) && (
            <Button color="primary" onClick={this.downloadArchive}>
              Télécharger l'historique
            </Button>
          )}
        </SectionTitle>
        <TablePagination
          component="div"
          className={classes.pagination}
          page={page}
          labelDisplayedRows={() => day.format('DD/MM/YYYY')}
          onChangePage={(e, newPage) => this.changePage(newPage)}
          rowsPerPageOptions={[]}
          rowsPerPage={0}
          count={0}
          backIconButtonProps={{ disabled: page === 0 }}
        />
        {!this.state.visits.length && (
          <NoDataScreen>Il n'y a pas d'historique</NoDataScreen>
        )}
        {!!this.state.visits.length && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell>Arrivé le</TableCell>
                <TableCell>Clôturé le</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.visits.map(({ id, name, closed_at, created_at }) => (
                <TableRow key={id}>
                  <TableCell>{name}</TableCell>
                  <TableCell>
                    {dayjs(created_at).format(locale.formats.LLLL)}
                  </TableCell>
                  <TableCell>
                    {dayjs(closed_at).format(locale.formats.LLLL)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
  inject('modelStore'),
  observer,
)(Archive);
