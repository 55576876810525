const playSound = () => {
  const audioContext = new AudioContext();
  var audio = document.getElementById('alert');

  if (audioContext.state !== 'running') {
    audioContext.resume();
  }

  audio.play();
};

export default playSound;
