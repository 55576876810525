import React from 'react';
import ReactDOM from 'react-dom';
import 'sanitize.css';
import './index.css';
import * as Sentry from '@sentry/browser';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

dayjs.locale('fr');

Sentry.init({
  dsn: 'https://412cde813ec01cdc68facdb8461cfeb2@o152603.ingest.us.sentry.io/4507662936244224',
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
