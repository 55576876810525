import React from 'react';
import { Typography, withStyles } from '@material-ui/core';

const styles = theme => ({
  title: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
});

const SectionTitle = ({ classes, children }) => (
  <Typography variant="h4" className={classes.title}>
    {children}
  </Typography>
);

export default withStyles(styles)(SectionTitle);
