import React, { useState, useRef, useEffect } from 'react';
import {
  withStyles,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  colors,
  IconButton,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import Button from './Button';
import { orderBy } from 'lodash';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SearchIcon from '@material-ui/icons/Search';

const styles = theme => ({
  button: {
    fontSize: '1.8em',
    height: 100,
    width: 300,
    margin: 'auto',
  },
  root: {
    display: 'flex',
  },
  container: {
    maxHeight: 215,
    background: colors.grey[200],
    overflowY: 'auto',
    padding: 20,
    flexGrow: 1,
  },
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr', // This ensures exactly two columns
    gap: 10,
    height: '100%',
  },
  item: {},
  label: {
    fontSize: '1.8em',
  },
  scrollButtons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 10,
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 20,
  },
  searchField: {
    marginRight: 20,
    flexGrow: 1,
  },
});

const AdvisorPicker = ({
  classes,
  goToStep,
  advisors,
  advisor,
  setAdvisor,
}) => {
  const containerRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAdvisors, setFilteredAdvisors] = useState(advisors);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const filtered = advisors.filter(
      advisor =>
        advisor.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        advisor.lastname.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setFilteredAdvisors(filtered);
  }, [searchTerm, advisors]);

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current) {
        setIsOverflowing(
          containerRef.current.scrollHeight > containerRef.current.clientHeight,
        );
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [filteredAdvisors]);

  const scrollList = direction => {
    if (containerRef.current) {
      const scrollAmount = direction === 'up' ? -100 : 100;
      containerRef.current.scrollTop += scrollAmount;
    }
  };

  if (!advisors.length) {
    goToStep(2);
    return null;
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.container} ref={containerRef}>
          <RadioGroup
            aria-label="advisor"
            name="advisor"
            value={advisor ? advisor.id : ''}
            onChange={e => {
              setAdvisor(advisors.find(({ id }) => id === e.target.value));
            }}
            className={classes.list}
          >
            {orderBy(
              filteredAdvisors,
              ['pushed_at', 'id'],
              ['desc', 'asc'],
            ).map(({ id, firstname, lastname }) => (
              <FormControlLabel
                className={classes.item}
                value={id}
                key={id}
                control={<Radio color="primary" />}
                label={
                  <Typography className={classes.label}>
                    {firstname} {lastname}
                  </Typography>
                }
              />
            ))}
          </RadioGroup>
        </div>
        {isOverflowing && (
          <div className={classes.scrollButtons}>
            <IconButton
              color="primary"
              onClick={() => scrollList('up')}
              style={{
                border: '1px solid ',
                borderRadius: '50%',
                padding: '6px',
                marginBottom: 16,
              }}
            >
              <KeyboardArrowUpIcon style={{ fontSize: '2rem' }} />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => scrollList('down')}
              style={{
                border: '1px solid ',
                borderRadius: '50%',
                padding: '6px',
              }}
            >
              <KeyboardArrowDownIcon style={{ fontSize: '2rem' }} />
            </IconButton>
          </div>
        )}
      </div>
      <div className={classes.searchContainer}>
        <TextField
          className={classes.searchField}
          variant="outlined"
          placeholder="Recherche"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          style={{ height: '100%' }}
        />
        <Button
          className={classes.button}
          disabled={!advisor}
          action={() => goToStep(2)}
          style={{ height: '100%' }}
        >
          OK
        </Button>
      </div>
    </>
  );
};

export default withStyles(styles)(AdvisorPicker);
