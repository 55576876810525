import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './List';
import Get from './Get';

const Organizations = () => {
  return (
    <>
      <Switch>
        <Route
          path="/dashboard/organizations/:organization_id"
          component={Get}
        />
        <Route path="/dashboard/organizations" component={List} />
      </Switch>
    </>
  );
};

export default Organizations;
